.new-user-modal-saveButton:active {
  filter: brightness(0.5);
}
.new-user-modal-cancelButton:active {
  filter: brightness(0.5);
}
.new-user-modal-cancelButton {
  padding-right: 2rem;
  padding-left: 2rem;
  background-color: var(--color-fith);
  color: rgb(var(--color-primary));
  border-radius: 5px;
  min-height: 2.5rem;
  border-color: rgb(var(--color-primary)) !important;
}

.new-user-modal-saveButton {
  padding-right: 2rem;
  padding-left: 2rem;
  background-color: rgb(var(--color-primary));
  color: var(--color-fith);
  min-height: 2.5rem;
  border-radius: 5px;
  border-color: rgb(var(--color-primary)) !important;
}

.new-user-modal-cancelButton:hover {
  padding-right: 2rem;
  padding-left: 2rem;
  background-color: var(--color-fith);
  color: rgb(var(--color-primary));
  border-radius: 5px;
  min-height: 2.5rem;
  border-color: rgb(var(--color-primary)) !important;
  box-shadow: 4px 6px 8px #aaaaaa;
}

.new-user-modal-saveButton:hover {
  padding-right: 2rem;
  padding-left: 2rem;
  background-color: rgb(var(--color-primary));
  color: var(--color-fith);
  min-height: 2.5rem;
  border-radius: 5px;
  border-color: rgb(var(--color-primary)) !important;
  box-shadow: 4px 6px 8px #aaaaaa;
}
